import './PaymentPage.css'
import { FC, RefObject, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useLoadingContext } from '../../contexts/LoadingContext'

function PaymentPage() {
  const location = useLocation()

  const { setIsLoading } = useLoadingContext()

  const iframeRef = useRef<HTMLIFrameElement>(null)
  useIsIFrameLoaded(iframeRef, setIsLoading)

  useEffect(() => {
    setIsLoading(true)
  }, [setIsLoading])

  return <IFrameRenderer url={location.state.url} iframeRef={iframeRef} />
}

export default PaymentPage

export const IFrameRenderer: FC<{
  url: string
  iframeRef?: RefObject<HTMLIFrameElement>
}> = ({ url, iframeRef }): JSX.Element => {
  return <iframe ref={iframeRef} src={url} title="payment-frame" allow="clipboard-write"></iframe>
}

export const useIsIFrameLoaded = (iframeRef: RefObject<HTMLIFrameElement>, setIsLoading: any) => {
  const iframeCurrent = iframeRef.current

  useEffect(() => {
    iframeCurrent?.addEventListener('load', () => setIsLoading(false))
    return () => {
      iframeCurrent?.removeEventListener('load', () => setIsLoading(false))
    }
  }, [iframeCurrent, setIsLoading])
}
