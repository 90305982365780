import './App.css'
import { useState } from 'react'
import errorIcon from './assets/img/error.svg'
import logoIcon from './assets/img/bead_icon.svg'
import { Route, Routes } from 'react-router-dom'
import PaymentPage from './components/PaymentPage/PaymentPage'
import Home from './components/Home/Home'
import { useLoadingContext } from './contexts/LoadingContext'

function App() {
  const [error, setError] = useState<string | undefined>(undefined)
  const [isError, setIsError] = useState(false)

  const { isLoading } = useLoadingContext()

  function handlePopupClick() {
    setIsError(false)
    setError(undefined)
  }

  return (
    <div className="App">
      <div className="container">
        {isError && (
          <div className="pop-up-container">
            <div className="pop-up">
              <img className="pop-up-icon" src={errorIcon} alt="error" />
              <div className="pop-up-text">{error}</div>
              <div className="pop-up-button" onClick={handlePopupClick}>
                OK
              </div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="pop-up-container">
            <div className="pop-up">
              <img className="pop-up-icon" src={logoIcon} alt="logo" />
              <div className="pop-up-text">Sending...</div>
            </div>
          </div>
        )}
        <Routes>
          <Route path="/" element={<Home setIsError={setIsError} setError={setError} />} />
          <Route path="/payment" element={<PaymentPage />} />
        </Routes>
      </div>
    </div>
  )
}

export default App
