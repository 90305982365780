import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Switch,
  HStack,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { LoginDetails } from '../../services/PaymentTerminalService'
import { CryptoPaymentRequest } from '../../models'

type Props = {
  open: boolean
  loginDetails: LoginDetails
  requestData: CryptoPaymentRequest
  onClose: () => void
  onApply: (props: { loginDetails: LoginDetails; requestData: CryptoPaymentRequest }) => void
}

export const SettingsModal = ({ open, loginDetails, requestData, onClose, onApply }: Props) => {
  const toast = useToast()
  const [login, setLogin] = useState(loginDetails)
  const [data, setData] = useState(requestData)

  return (
    <Modal isOpen={open} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>Login</Tab>
              <Tab>Terminal</Tab>
              <Tab>Customer Name</Tab>
              <Tab>Customer Address</Tab>
            </TabList>

            <TabPanels>
              {/* Login */}
              <TabPanel>
                <FormControl>
                  <FormLabel>Username</FormLabel>
                  <Input
                    value={login.username}
                    onChange={(v) => setLogin((l) => ({ ...l, username: v.target.value }))}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    value={login.password}
                    onChange={(v) => setLogin((l) => ({ ...l, password: v.target.value }))}
                  />
                </FormControl>
              </TabPanel>
              {/* Terminal */}
              <TabPanel>
                <FormControl>
                  <HStack>
                    <Button
                      onClick={() => {
                        toast({
                          title: 'Terminal Updated.',
                          description: 'Terminal with all options enabled.',
                          status: 'success',
                          duration: 3000,
                          isClosable: true,
                        })
                        setLogin((l) => ({
                          ...l,
                          username: '657fc268185cb58230377342@beadpay.io',
                          password: 'MShQpBszDCVn3z4',
                        }))
                        setData((l) => ({
                          ...l,
                          terminalId: '657fc268185cb58230377342',
                          merchantId: '657fbd83185cb58230377341',
                        }))
                      }}
                    >
                      Enabled all options
                    </Button>
                    <Button
                      onClick={() => {
                        toast({
                          title: 'Terminal Updated.',
                          description: 'Terminal without options enabled.',
                          status: 'success',
                          duration: 3000,
                          isClosable: true,
                        })
                        setLogin((l) => ({
                          ...l,
                          username: '6598baf37e5391825b0e23bf@beadpay.io',
                          password: 'MShQpBszDCVn3z4',
                        }))
                        setData((l) => ({
                          ...l,
                          terminalId: '6598baf37e5391825b0e23bf',
                          merchantId: '657fbd83185cb58230377341',
                        }))
                      }}
                    >
                      Disable all options
                    </Button>
                  </HStack>
                </FormControl>
                <FormControl>
                  <FormLabel>Terminal Id</FormLabel>
                  <Input
                    value={data.terminalId}
                    onChange={(v) => setData((l) => ({ ...l, terminalId: v.target.value }))}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Merchant Id</FormLabel>
                  <Input
                    value={data.merchantId}
                    onChange={(v) => setData((l) => ({ ...l, merchantId: v.target.value }))}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Redirect URL</FormLabel>
                  <Input
                    type="url"
                    value={data.redirectUrl || ''}
                    onChange={(v) => setData((l) => ({ ...l, redirectUrl: v.target.value }))}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Reference</FormLabel>
                  <Input
                    value={data.reference || ''}
                    onChange={(v) => setData((l) => ({ ...l, reference: v.target.value }))}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Input
                    value={data.description || ''}
                    onChange={(v) => setData((l) => ({ ...l, description: v.target.value }))}
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center" pt="16px">
                  <FormLabel>Email Receipt?</FormLabel>
                  <Switch
                    isChecked={data.emailReceipt}
                    onChange={(v) => setData((l) => ({ ...l, emailReceipt: !l.emailReceipt }))}
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center" pt="16px">
                  <FormLabel>SMS Receipt?</FormLabel>
                  <Switch
                    isChecked={data.smsReceipt}
                    onChange={(v) => setData((l) => ({ ...l, smsReceipt: !l.smsReceipt }))}
                  />
                </FormControl>
              </TabPanel>
              {/* Customer Name */}
              <TabPanel>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={data.customer?.email || ''}
                    onChange={(v) =>
                      setData((l) => ({ ...l, customer: { ...l.customer, email: v.target.value } }))
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    type="phone"
                    value={data.customer?.phone || ''}
                    onChange={(v) =>
                      setData((l) => ({ ...l, customer: { ...l.customer, phone: v.target.value } }))
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    value={data.customer?.firstName || ''}
                    onChange={(v) =>
                      setData((l) => ({
                        ...l,
                        customer: { ...l.customer, firstName: v.target.value },
                      }))
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    value={data.customer?.lastName || ''}
                    onChange={(v) =>
                      setData((l) => ({
                        ...l,
                        customer: { ...l.customer, lastName: v.target.value },
                      }))
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Birthday</FormLabel>
                  <Input
                    type="date"
                    value={data.customer?.birthday || ''}
                    onChange={(v) =>
                      setData((l) => ({
                        ...l,
                        customer: { ...l.customer, birthday: v.target.value },
                      }))
                    }
                  />
                </FormControl>
              </TabPanel>
              {/* Customer Address */}
              <TabPanel>
                <FormControl>
                  <FormLabel>Address</FormLabel>
                  <Input
                    value={data.customer?.address || ''}
                    onChange={(v) =>
                      setData((l) => ({
                        ...l,
                        customer: { ...l.customer, address: v.target.value },
                      }))
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Address 2</FormLabel>
                  <Input
                    value={data.customer?.address2 || ''}
                    onChange={(v) =>
                      setData((l) => ({
                        ...l,
                        customer: { ...l.customer, address2: v.target.value },
                      }))
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>City</FormLabel>
                  <Input
                    value={data.customer?.city || ''}
                    onChange={(v) =>
                      setData((l) => ({ ...l, customer: { ...l.customer, city: v.target.value } }))
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>State</FormLabel>
                  <Input
                    value={data.customer?.state || ''}
                    onChange={(v) =>
                      setData((l) => ({ ...l, customer: { ...l.customer, state: v.target.value } }))
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Country Code</FormLabel>
                  <Input
                    value={data.customer?.countryCode || ''}
                    onChange={(v) =>
                      setData((l) => ({
                        ...l,
                        customer: { ...l.customer, countryCode: v.target.value },
                      }))
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Postal Code</FormLabel>
                  <Input
                    value={data.customer?.postalCode || ''}
                    onChange={(v) =>
                      setData((l) => ({
                        ...l,
                        customer: { ...l.customer, postalCode: v.target.value },
                      }))
                    }
                  />
                </FormControl>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => onApply({ loginDetails: login, requestData: data })}
          >
            Apply
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
