import './Terminal.css'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import KeyPad from './Keypad'
import sendPaymentRequest, { LoginDetails } from '../../services/PaymentTerminalService'
import { useLoadingContext } from '../../contexts/LoadingContext'
import { ChakraProvider } from '@chakra-ui/react'
import { SettingsModal } from '../SettingsModal/SettingsModal'
import { CryptoPaymentRequest, CryptoPaymentResponse } from '../../models'

const USERNAME = process.env.REACT_APP_USERNAME || ''
const PASSWORD = process.env.REACT_APP_PASSWORD || ''
const TERMINAL_ID = process.env.REACT_APP_TERMINAL_ID || ''
const MERCHANT_ID = process.env.REACT_APP_MERCHANT_ID || ''

const data = {
  terminalId: TERMINAL_ID,
  merchantId: MERCHANT_ID,
  reference: 'abc123',
  description: 'test',
  customer: {
    email: 'test@beadpay.io',
    phone: '+12369902027',
    firstName: 'Test',
    lastName: 'Test',
    address: '123 William Street',
    address2: null,
    city: 'New York',
    state: 'NY',
    countryCode: 'US',
    postalCode: '10038',
    birthday: '1970-04-28',
  },
  cartItems: [],
  redirectUrl: '',
  emailReceipt: true,
  smsReceipt: false,
  paymentUrlType: 'web',
  requestedAmount: 0,
} as CryptoPaymentRequest

function Terminal({ setIsError, setError }: { setIsError: any; setError: any }) {
  const [settings, setSettings] = useState<{
    loginDetails: LoginDetails
    requestData: CryptoPaymentRequest
  }>({
    loginDetails: { username: USERNAME, password: PASSWORD },
    requestData: data,
  })
  const [settingsModalOpen, setSettingsModalOpen] = useState(false)

  const DefaultPrice = '0.00'
  const navigate = useNavigate()

  const { setIsLoading } = useLoadingContext()

  const [currValue, setValue] = useState('')
  const [currPrice, setPrice] = useState(0.0)
  const [currShownPrice, setShownPrice] = useState(DefaultPrice)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  useEffect(() => {
    setIsLoading(false)
  }, [setIsLoading])

  function setIsLoadingLocal(isLoading: boolean) {
    setIsSubmitDisabled(isLoading)
    setIsLoading(isLoading)
  }

  function parseCurrValue(val: string) {
    let intPart: string
    let decimalPart: string
    if (val.length < 3) {
      intPart = '0'
      decimalPart = val
    } else {
      intPart = val.substring(0, val.length - 2)
      decimalPart = val.substring(val.length - 2, val.length)
    }

    let intNum = parseInt(intPart)
    const WrongNumber = 'Wrong number'

    if (isNaN(intNum)) {
      showError(WrongNumber)
      return
    }

    let intDecimal = parseInt(decimalPart)

    if (isNaN(intDecimal)) {
      showError(WrongNumber)
      return
    }

    const res = intNum + Number.parseFloat((intDecimal / 100).toFixed(2))
    setPrice(res)
    const shownPrice = res.toFixed(2)
    setShownPrice(shownPrice)
  }

  function handleClick(val: string) {
    setValue(val)

    if (val === '') {
      setPrice(0)
      setShownPrice(DefaultPrice)
      setIsSubmitDisabled(true)
      return
    }

    if (parseFloat(val) < 1) {
      setIsSubmitDisabled(true)
    } else {
      setIsSubmitDisabled(false)
    }
    parseCurrValue(val)
  }

  function handleSubmitClick() {
    setIsLoadingLocal(true)
    sendPaymentRequest(currPrice, settings.requestData, settings.loginDetails)
      .then((result) => {
        if (result) {
          processResult(result)
        } else {
          processErrorResult('An error occurred during creation of a payment.')
        }
      })
      .catch(() => {
        processErrorResult('An error occurred during creation of a payment.')
      })
      .finally(() => {
        setIsLoadingLocal(false)
      })
  }

  function processResult(res: CryptoPaymentResponse) {
    if (!res.paymentUrls?.[0].url) {
      showError('Something went wrong')
    } else {
      navigate('/payment', {
        state: { url: res.paymentUrls?.[0].url },
      })
    }
  }

  function processErrorResult(error: string) {
    showError(error)
  }

  function showError(message: string) {
    setError(message)
    setIsError(true)
  }

  return (
    <>
      <div className="price" onClick={() => setSettingsModalOpen(true)}>
        <div className="value">${currShownPrice}</div>
        <div className="currency">USD</div>
      </div>
      <KeyPad currValue={currValue} onClick={handleClick} />
      <div
        className="submit"
        aria-disabled={isSubmitDisabled}
        onClick={() => !isSubmitDisabled && handleSubmitClick()}
      >
        CHARGE
      </div>
      <ChakraProvider>
        <SettingsModal
          loginDetails={settings.loginDetails}
          requestData={settings.requestData}
          open={settingsModalOpen}
          onClose={() => setSettingsModalOpen(false)}
          onApply={({ loginDetails, requestData }) => {
            setSettings({ loginDetails, requestData })
            setSettingsModalOpen(false)
          }}
        />
      </ChakraProvider>
    </>
  )
}

export default Terminal
