import { Dispatch, createContext, useContext, useState } from 'react'

interface LoadingContextValue {
  isLoading: boolean
  setIsLoading: Dispatch<React.SetStateAction<boolean>>
}

const LoadingContext = createContext<LoadingContextValue | undefined>(undefined)

export const LoadingProvider = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState(false)

  // the value passed in here will be accessible anywhere in our application
  // you can pass any value, in our case we pass our state and it's update method
  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}

// useLoadingContext will be used to use and update state accross the app
// we can access to data and setData using this method
// anywhere in any component that's inside ToggleProvider
export const useLoadingContext = () => {
  const loadingContext = useContext(LoadingContext)

  if (loadingContext === undefined) {
    throw new Error('useLoadingContext must be inside a LoadingProvider')
  }

  return loadingContext
}
