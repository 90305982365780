import './Home.css'
import Terminal from '../Terminal/Terminal'

function Home({ setIsError, setError }: { setIsError: any; setError: any }) {
  return (
    <div className="content">
      <Terminal setIsError={setIsError} setError={setError} />
    </div>
  )
}

export default Home
