import deleteIcon from '../../assets/img/delete.svg'

function KeyPad({ currValue, onClick }: { currValue: string; onClick: any }) {
  function handleNumClick(val: number) {
    if (val === 0 && currValue.length === 0) {
      return
    }

    if (currValue.length >= 8) {
      return
    }

    currValue += val
    onClick(currValue)
  }

  function handleClearClick() {
    currValue = ''
    onClick(currValue)
  }

  function handleDoubleZeroClick() {
    if (currValue.length === 0) {
      return
    }
    if (currValue.length + 2 > 8) {
      return
    }
    currValue += '00'
    onClick(currValue)
  }

  return (
    <>
      <div className="keypad">
        <div className="key-hitbox" onClick={() => handleNumClick(7)}>
          <div className="key-visual">7</div>
        </div>
        <div className="key-hitbox" onClick={() => handleNumClick(8)}>
          <div className="key-visual">8</div>
        </div>
        <div className="key-hitbox" onClick={() => handleNumClick(9)}>
          <div className="key-visual">9</div>
        </div>
        <div className="key-hitbox" onClick={() => handleNumClick(4)}>
          <div className="key-visual">4</div>
        </div>
        <div className="key-hitbox" onClick={() => handleNumClick(5)}>
          <div className="key-visual">5</div>
        </div>
        <div className="key-hitbox" onClick={() => handleNumClick(6)}>
          <div className="key-visual">6</div>
        </div>
        <div className="key-hitbox" onClick={() => handleNumClick(1)}>
          <div className="key-visual">1</div>
        </div>
        <div className="key-hitbox" onClick={() => handleNumClick(2)}>
          <div className="key-visual">2</div>
        </div>
        <div className="key-hitbox" onClick={() => handleNumClick(3)}>
          <div className="key-visual">3</div>
        </div>
        <div className="key-hitbox" onClick={() => handleDoubleZeroClick()}>
          <div className="key-visual">00</div>
        </div>
        <div className="key-hitbox" onClick={() => handleNumClick(0)}>
          <div className="key-visual">0</div>
        </div>
        <div className="key-hitbox" onClick={() => handleClearClick()}>
          <div className="key-visual">
            <img className="svg-delete" src={deleteIcon} alt="delete icon" />
          </div>
        </div>
      </div>
    </>
  )
}

export default KeyPad
